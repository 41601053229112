import dayjs from "dayjs";

export function convertToSelect(objectType) {
    let select = []
    for(const key in objectType) {
        select.push({name: objectType[key], value: key});
    }
    return select;
}

export function convertStringListToSelect(array) {
    let select = [];
    for(const title of array) {
        select.push({name: title, value: title});
    }
    return select;
}

export function convertSelectModelToSelct(selects) {
    let newSelects = [];
    for (const select of selects) {
        newSelects.push({name: select.value, value: select.value})
    }
    return newSelects;
}

export function getFormattedDate(date) {
      return date != null ? dayjs(date).format("DD/MM/YYYY") : null;
}

export function arrondirKeuros(nombre) {
    return (nombre / 1000).toLocaleString('fr-FR', { minimumFractionDigits: 0, maximumFractionDigits: 0});
}